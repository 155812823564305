$(function () {
    $('.main-slider').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slide: '.slide',
        dots: false,
        fade: true,
        arrows: false,
    });

    $('.mobile-menu-button').click(function () {
       $('header').addClass('menu-active');
    });

    $('.close-mobile-menu-button').click(function () {
       $('header').removeClass('menu-active');
    });

    $('.main-slider .next').click(function () {
       $('.main-slider').slick('slickNext');
    });

    $('.main-slider .prev').click(function () {
       $('.main-slider').slick('slickPrev');
    });

    $('.main-slider .slide-numbers a').click(function () {
        $('.main-slider .slide-numbers a').removeClass('active');
        $(this).addClass('active');
        var dotIndex = $(this).data('index');
        $('.main-slider').slick('slickGoTo', dotIndex);

    })

    $(".main-slider").on("afterChange", function (event, slick, currentSlide, nextSlide){
        $('.yvideo').each(function(){
            var el_src = $(this).attr("src");
            $(this).attr("src",el_src);
        });
        $('.main-slider .slide-numbers a').removeClass('active');
        $(".main-slider .slide-numbers").find("[data-index='" + currentSlide + "']").addClass('active');

    });


    $('.products-slider').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slide: '.slide',
        dots: false,
        arrows: false,
    });
    $('.products-slider .next').click(function () {
        $('.products-slider').slick('slickNext');
    });

    $('.products-slider .prev').click(function () {
        $('.products-slider').slick('slickPrev');
    });


    $('.product-detail-slider').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slide: '.slide',
        dots: false,
        arrows: false,
    });
    $('.product-detail-slider .next').click(function () {
        $('.product-detail-slider').slick('slickNext');
    });

    $('.product-detail-slider .prev').click(function () {
        $('.product-detail-slider').slick('slickPrev');
    });
});